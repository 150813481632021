import { R_Display } from "../../R_Style/R_Style"
import "./Footer.css"

type props = {
  style?: React.CSSProperties
}

export default function Footer(Props: props) {
  return (
    <footer className="site-footer" style={Props.style}>
      <div className="mainContent">
        <div style={{ marginBlock: "10px ", ...R_Display("column", "flex-start", "center") }}>
          <img src="/@R036_images/main/R-036-LOGO(白色).svg" style={{ width: "50px" }} />
          {/* <span style={{ fontSize: "12px", color: "gray" }}>部落格</span> */}
          <p style={{ marginInline: "20px" }} className="footer_content">
            研發室036號，一個專注在資訊科技與人文的地方
          </p>
          <div>
            <a href="/privacyPolicy" style={{ marginRight: "20px", fontSize: "14px", color: "rgb(219, 219, 219)" }}>
              網站條款與聲明
            </a>
            <a href="/profile" style={{ marginRight: "20px", fontSize: "14px", color: " rgb(219, 219, 219)" }}>
              關於開發者
            </a>
          </div>
        </div>

        <hr />
      </div>
      <div style={{ ...R_Display("row", "center", "center") }}>
        {/* <span style={{ fontSize: "12px", color: "gray", marginRight:"20px" }}>版本 2.0.0-beta</span> */}
        <p className="footer_info ">版本 2.0.0-beta, Copyright &copy; 2023-2025 All Rights Reserved by Rayder </p>
      </div>
    </footer>
  )
}
