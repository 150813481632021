import { Button, Skeleton } from "@mui/material"
import { Link } from "react-router-dom"
import BaseCard from "../../@R036/R_Asset/Card/BaseCard"

import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useEffect, useState } from "react"
import { MovieAPI } from "../../API/movieAPI"

type movieModel = {
  title: string
  img: string
  date: Date
}

export default function MovieRoomAll() {
  const [movies, set_movies] = useState<Array<movieModel>>([])
  const [isLoading_movies, set_isLoading_movies] = useState(false)

  async function fetchData_movies() {
    try {
      set_isLoading_movies(true)
      const data = await MovieAPI() // 假設 MovieAPI 是一個返回數據的函數
      set_movies(data) // 更新 mainData 狀態

      setTimeout(() => {}, 300) //故意延遲
    } catch (error) {
      console.error("Error loading data:", error)
    } finally {
      set_isLoading_movies(false)
    }
  }
  //初始載入資料
  useEffect(() => {
    fetchData_movies()
  }, [])
  return (
    <>
      <div style={{ margin: "20px" }}>
        <div style={{marginTop: "50px"}}>
          <Link style={{ color: "black" }} to={"/movies"}>
            <Button color="inherit">
              <ArrowBackIcon />
              <span style={{ fontFamily: "Noto Sans TC, sans-serif" }}>返回</span>
            </Button>
          </Link>
        </div>

        <div className="gridBoxAll">
          {isLoading_movies
            ? Array.from({ length: 8 }).map((_, index) => <Skeleton key={index} variant="rounded" width={130} height={180} style={{ borderRadius: "10px", margin: 15 }} />)
            : movies?.map((one) => (
                <div style={{ margin: "15px" }}>
                  <BaseCard isSelected image={one.img} width={130} height={180} />
                  <div className={"One_theme_number"} style={{ fontSize: "14px", color: "gray", marginBlock: "10px" }}>
                    {new Date(one.date).toLocaleDateString("zh-TW", { year: "numeric", month: "2-digit" })}
                  </div>
                  <p>{one.title}</p>
                </div>
              ))}
        </div>
      </div>
    </>
  )
}
