import { Box, Divider, Pagination, Paper, Skeleton } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import ArticleCard from "../../@R036/R_Asset/Card/ArticleCard/ArticleCard"
import Footer from "../../@R036/R_Base/Footer/Footer"
import { LeftRightFrame } from "../../@R036/R_Base/LeftRightFrame/LeftRightFrame"
import HideAppBar from "../../@R036/R_Mui/AppBar"
import SearchBase from "../../@R036/R_Mui/SearchBase"
import { R_Display } from "../../@R036/R_Style/R_Style"
import ArticleAPI from "../../API/ArticleAPI"
import "./article.css"
import { ArticleContext } from "./articleContext"
import { TagBar } from "./TagBar"
// import { useArticleState } from "./articleContext"

export default function Article() {
  //網頁架構
  const [isLoading, setIsLoading] = useState(true)
  //設定篩選Tag
  const TagList = ["全部", "前端", "後端", "人工智慧",  "筆記","資料結構", "系統設計"]

  // 文章資料
  const [pagination, set_pagination] = useState(10)
  const [articles, set_articles] = useState<Array<any>>()
  const [filter, set_filter] = useState<any>("") // 儲存查詢參數中的 filter 值
  const [search, set_search] = useState("")

  //TODO 全局資料 Context
  const { savedState, setSavedState } = useContext(ArticleContext)
  useEffect(() => {
    // 設置滾動位置
    window.scrollTo(0, savedState.scrollY)
    // set_currentPage(savedState.currentPage)
    // 假設你需要根據 savedState.currentPage 恢復文章頁面，可以在這裡處理
    // 這裏的 pageState.currentPage 可以用來設置顯示當前頁面內容
    console.log("恢復到頁面: ", savedState.scrollY)
    console.log("恢復到頁面: ", savedState.currentPage)
  }, [])
  const handleScroll = () => {
    // 保存滾動位置
    setSavedState((prev: any) => ({
      ...prev,
      scrollY: window.scrollY,
    }))
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  //物件
  const articleAPI = new ArticleAPI()

// 方法: 獲取文章資料
async function fetchDataApi(filterParam: any, search: any = "", currentPage: number = 1) {
  try {
    setIsLoading(true);

    const articlesAll = await articleAPI.Read_articles("information-tech,artificial-intelligence", filterParam, search)
    const numberPage = Math.ceil(articlesAll?.length / 10);
    set_pagination(numberPage);
    console.log("總資料數目: " + articlesAll?.length, "總頁數: " + numberPage);

    // 分頁資料
    const articles = await articleAPI.Read_articles("information-tech,artificial-intelligence", filterParam, search,currentPage)

    set_articles(articles);
    console.log(articles);

    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  } catch (error) {
    console.error(error);
  }
}

  // 初始仔入
  // 1. 從 URL 中獲取 filter 參數
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const filterParam = searchParams.get("filter") // 獲取 URL 中的 filter 參數
    set_filter(filterParam)
    console.log("初始選擇標籤:" + filterParam)
    fetchDataApi(filterParam, search, savedState.currentPage)
  }, []) // 組件加載時執行一次

  // 重新加載資料
  useEffect(() => {
    if (filter) {
      setSavedState((prev: any) => ({
        ...prev,
        currentPage: 1,
      }))
      fetchDataApi(filter, search, 1)
      console.log("重新加載 選擇標籤:" + filter)
    }
  }, [filter])

  const triggerSearch = (search: string) => {
    console.log("搜尋關鍵字:", search)
    setSavedState((prev: any) => ({
      ...prev,
      currentPage: 1,
    }))
    set_search(search)
    fetchDataApi(filter, search, 1)
  }

  return (
    <div className="article-main text_RWD">
      <HideAppBar />

      <LeftRightFrame
        leftContent={
          <>
            {isLoading ? Array.from({ length: 10 }).map((_, index) => <Skeleton key={index} variant="rounded" height={"8em"} style={{ borderRadius: "10px", marginBottom: 35 }} />) : articles?.map((n, index) => <ArticleCard key={index} data={n} />)}
            <div style={{ marginBlock: "30px", ...R_Display("column", "center", "center") }}>
              <Pagination
                count={pagination}
                page={savedState.currentPage}
                shape="rounded"
                onChange={(event, page) => {
                  console.log("選擇頁數" + page)
                  setSavedState((prev: any) => ({
                    ...prev,
                    currentPage: page,
                  }))
                  fetchDataApi(filter, search, page)
                }}
              />
            </div>
          </>
        }
        rightContent={
          <>
            {/* 手機 */}
            <Box sx={{ width: "100%", flexGrow: 1, display: { xs: "flex", sm: "none" } }}>
              <div style={{ ...R_Display("column", "center", "flex-start"), overflow: "hidden" }}>
                <div style={{ marginBottom: "15px" }}>
          
                  <SearchBase placeholder={"搜尋主題"} search={search} set_search={set_search} triggerSearch={triggerSearch} />
                </div>
                <TagBar onChange={set_filter} TagList={TagList} />
              </div>
            </Box>

            {/* 電腦 */}
            <Box sx={{ height: "100%" , flexGrow: 0, display: { xs: "none", sm: "flex" } }}>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-start", height: "100%" }}>
                <Divider sx={{ marginInline: "30px" }} orientation="vertical" flexItem />
                <div style={{ ...R_Display("column", "flex-start", "flex-start"), height: "100%" }}>
                  <SearchBase placeholder={"搜尋文章主題"} search={search} set_search={set_search} triggerSearch={triggerSearch} />

                  <Paper elevation={0} sx={{ marginTop: "30px", padding: 2, backgroundColor: "white", borderRadius: "10px" }}>
                    <h4>主題</h4>
                    <TagBar onChange={set_filter} TagList={TagList} />
                  </Paper>
                </div>
              </div>
            </Box>
          </>
        }
      />
      <Footer />
    </div>
  )
}
