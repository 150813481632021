import { Button } from "@mui/material"
import React, { Dispatch, SetStateAction } from "react"
import { R_Color, R_Display } from "../../R_Style/R_Style"
import "./EventDialogBase.css"

type EventDialogBaseProps = {
  isActive: boolean
  set_isActive: Dispatch<SetStateAction<boolean>>
  event: any
  text_title?: string
  text_content?: string
  text_s_content?: string
  Button_cancel?: string
  Button_ok?: string
  sx?: React.CSSProperties
  width?: string
  heigh?: string
}

export default function EventDialogBase({ Button_cancel = undefined, Button_ok = undefined, ...props }: EventDialogBaseProps) {
  return (
    <div className={`event-dialog ${props.isActive ? "opened" : ""}`} style={{ width: props.width, ...props.sx }}>
      <p>{props.text_title}</p>
      <p>
        <span style={{ marginLeft: "8px" }} className="textContainer">
          {props.text_content}
        </span>
      </p>
      <p className="s_textContainer">{props.text_s_content}</p>
      <div className="buttonContent" style={R_Display("row", "flex-end", "center")}>
        {Button_cancel ? (
          <Button
            size="medium"
            style={{ color: "black", marginRight: "15px", fontWeight: 600 }}
            onClick={() => {
              props.set_isActive(!props.isActive)
              console.log(props.isActive)
            }}
          >
            {Button_cancel}
          </Button>
        ) : (
          <></>
        )}
        {Button_ok ? (
          <Button size="medium" style={{ backgroundColor: R_Color().gray, fontWeight: 600 }} variant="contained" onClick={props.event}>
            {Button_ok}
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
