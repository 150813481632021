import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Slide from "@mui/material/Slide"
import Toolbar from "@mui/material/Toolbar"
import useScrollTrigger from "@mui/material/useScrollTrigger"
import React, { useEffect, useState } from "react"
import DrawerList from "../../Component/drawerList"
import Backdrop from "../R_Base/backdrop/backdropBase"
import BlogTitle from "../R_Base/BlogTitle/BlogTitle"
import AnimationButton from "../R_Base/Button/animationButton"
import TopBar from "../R_Base/Topbar/TopBar"
import { Theme } from "./Theme"
import SimpleTransitionsSlide from "./TransitionsSlide"
interface Props {
  window?: () => Window // 新增參數支持自定義容器
  children?: React.ReactElement<unknown>
  isWhite?: boolean
}
const drawlist = [
  { title: "首頁", scr: "/home" },
  { title: "軟體", scr: "/articles" },
  { title: "設計", scr: "/systemDesign" },
  { title: "科幻", scr: "/movies" },
]
function HideOnScroll({ children, window }: Props) {
  // 使用 `useScrollTrigger` 來檢查滾動狀態，支持自定義容器
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  })
  // console.log("Scroll Trigger Active:", trigger) // 查看觸發狀態

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children ?? <div />}
    </Slide>
  )
}

export default function HideAppBar(props: Props) {
  const [isMenu, set_isMenu] = useState(false)
  const [bgColor, setBgColor] = useState("rgba(0, 0, 0, 0)") // 預設為完全透明背景色

  const handleScroll = () => {
    if (window.scrollY === 0) {
      setBgColor("rgba(0, 0, 0, 0)") // 畫面在頂部時，背景透明
    } else {
      setBgColor(props.isWhite ? "rgb(0, 0, 0)" : "rgb(255, 255, 255)") // 滾動後，設定背景色半透明
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    // 初始化檢查，確保進入畫面時正確設定顏色
    handleScroll()

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])
  return (
    <React.Fragment>
      
        {/* <CssBaseline /> */}
        <HideOnScroll {...props}>
          <AppBar
            sx={{
              zIndex: 900,
              backgroundColor: bgColor, // 動態背景色
              transition: "background-color 0.5s ease-in-out", // 背景色平滑過渡
            }}
          >
            <Toolbar>
              <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "flex" } }}>
                <BlogTitle use_my_logo={true} style={{ height: "30px" }} color={props.isWhite ? "white" : "black"} name={""} />
              </Box>

              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                <TopBar title_List={drawlist} isWhite={props.isWhite}></TopBar>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, justifyContent: "flex-end" }}>
                <AnimationButton
                  active={() => {
                    set_isMenu(!isMenu)
                  }}
                  isWhite={props.isWhite}
                  isOpen={isMenu}
                />
                <SimpleTransitionsSlide touch timeout={[1000, 400, 250]} transition={"left"} isActive={isMenu} position_row={"end"} position_column={"start"}>
                  <div style={{ zIndex: 1000, height: "100vh" }}>
                    <DrawerList
                      active={() => {
                        set_isMenu(!isMenu)
                      }}
                    />
                  </div>
                </SimpleTransitionsSlide>
                <Backdrop
                  open={isMenu}
                  set_open={function (): void {
                    set_isMenu(!isMenu)
                  }}
                ></Backdrop>
              </Box>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
   
    </React.Fragment>
  )
}

