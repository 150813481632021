import { Skeleton } from "@mui/material"
import React from "react"

const SkeletonArticle = () => {
  return (
    <div>
      <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
      <Skeleton variant="text" sx={{ marginBottom: "20px", fontSize: "3rem" }} />
      <Skeleton variant="text" width={200} sx={{ fontSize: "1rem" }} />
      <Skeleton variant="text" width={100} sx={{ marginBlock: "20px", fontSize: "3rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      <Skeleton variant="text" width={200} sx={{ marginBlock: "20px", fontSize: "3rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      <Skeleton variant="text" width={200} sx={{ marginBlock: "20px", fontSize: "3rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
    </div>
  )
}

export default SkeletonArticle
