import React, { createContext, useState, useContext } from "react";

interface MusicContextType {
  musicId: string;
  set_MusicId: React.Dispatch<React.SetStateAction<string>>;
  isTurnOn: boolean;
  set_isTurnOn: React.Dispatch<React.SetStateAction<boolean>>;
  isPlaying: string | null;
  set_isPlaying: React.Dispatch<React.SetStateAction<string | null>>;
  isHidden  : boolean;
  set_isHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

const MusicContext = createContext<MusicContextType | undefined>(undefined);

export const MusicProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [musicId, set_MusicId] = useState<string>("0J6tOwRpy4lFpFqnYD5VxI"); // 默认 musicId
  const [isTurnOn, set_isTurnOn] = useState<boolean>(false); // 播放器是否開啟
  const [isPlaying, set_isPlaying] = useState<string | null>(null); // 當前播放的歌曲 ID
  
  const [isHidden, set_isHidden] = useState<boolean>(false); // 播放器是否隱藏

  return (
    <MusicContext.Provider
      value={{
        musicId,
        set_MusicId,
        isTurnOn,
        set_isTurnOn,
        isPlaying,
        set_isPlaying,
        isHidden,
        set_isHidden
      }}
    >
      {children}
    </MusicContext.Provider>
  );
};

export const useMusic = (): MusicContextType => {
  const context = useContext(MusicContext);
  if (!context) {
    throw new Error("useMusic must be used within a MusicProvider");
  }
  return context;
};
