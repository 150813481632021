import { useEffect } from "react"
import { R_Display, R_Display_bata } from "../../R_Style/R_Style"
import "./BlogTitle.css"
import { Link } from "react-router-dom"

type BlogTitle = {
  color: "white" | "black"
  use_my_logo?: boolean
  name?: string
  style?: React.CSSProperties
  nolink? : boolean
}

export default function BlogTitle(props: BlogTitle) {
  const color = props.color
  const link = props.nolink?"#":"/"
  const logo =
    props.color === "white" ? 
    <img style={{ height: "100%", boxSizing: "border-box" }} src="/@R036_images/main/R-036-LOGO(白色).svg" alt="SVG Image" /> :
   <img style={{ height: "100%", boxSizing: "border-box" }} src="/@R036_images/main/R-036-LOGO(灰色).svg" alt="SVG Image" />

  return (
    <Link className={"BlogTitle"} to={link} style={{ ...props.style, ...R_Display("row", "flex-start", "flex-end"), color: color }}>
      {props.use_my_logo ? logo : <span className="One_theme_number">R036.v1</span>}
      {/* <span className="BlogTitleName Font_Family_Oswald ">{props.name}</span> */}
      <span className="BlogTitleName Font_Family_NoteSans " style={{marginLeft:"10px"}}>{props.name}</span>
    </Link>
  )
}
