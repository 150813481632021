import React, { useEffect } from "react";
import "./backdropBase.css";

type BackdropProps = {
  children?: React.ReactNode;
  backdrop?: boolean;
  open: boolean;
  set_open: Function;
};

export default function Backdrop(Props: BackdropProps) {
  useEffect(() => {
    if (Props.open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [Props.open]);

  return (
    <div className={`backdrop ${Props.open ? 'open' : ''}`}>
      {/* 顯示子類（初始隱藏，開啟時顯示） */}
      <div
        className={`backdrop-content ${Props.open ? "show" : ""}`}
      >
        {Props.children}
      </div>

      {/* 黑色遮罩 */}
      {Props.open ? (
        <div
          className="black"
          onClick={() => {
            Props.set_open(!Props.open);
          }}
        ></div>
      ) : null}
    </div>
  );
}
