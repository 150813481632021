import "./LeftRightFrame.css"

type LeftRightFrameProps = {
  leftContent: React.ReactNode // 左邊的內容
  rightContent: React.ReactNode // 右邊的內容
  style?: React.CSSProperties // 額外樣式
}

export function LeftRightFrame({ leftContent, rightContent, style }: LeftRightFrameProps) {
  return (
    <div className="left-right-frame" style={style}>
      <div className="left-side">{leftContent}</div>
      <div className="right-side">{rightContent}</div>
    </div>
  )
}
