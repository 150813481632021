import React, { useContext, useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"
// import './article.css'; // 假設你有一個專門的 CSS 文件來樣式化文章
// import rehypeRaw from "rehype-raw"
import remarkGfm from "remark-gfm"
import "./articlePage.css"

import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Box, Button, Paper, Skeleton } from "@mui/material"
import { Link, useLocation, useParams } from "react-router-dom"
import Footer from "../../@R036/R_Base/Footer/Footer"
import LoadingPage from "../../@R036/R_Spring/loadingPage"
import ArticleAPI from "../../API/ArticleAPI"
import SkeletonArticle from "../../@R036/R_Mui/Skeleton_article"
import { ArticleContext } from "./articleContext"

import remarkMath from "remark-math"
import rehypeKatex from "rehype-katex"
import "katex/dist/katex.min.css"
import SyntaxHighlighter from "react-syntax-highlighter"
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs"
import { extractHeadings, Heading } from "./articleCatalog"
import SimpleTransitionsSlide from "../../@R036/R_Mui/TransitionsSlide"
import { useMusic } from "../../Component/spotify_server/MusicContext"
import MenuOpenIcon from "@mui/icons-material/MenuOpen"
function ArticlePage() {
  const { type, code } = useParams<{ type: string; code: string }>() // 指定參數類型
  const [markdown, setMarkdown] = useState<any>("")
  const [headings, setHeadings] = useState<Heading[]>([]) // 新增一個狀態來存儲目錄數據

  const [isLoading, set_isLoading] = React.useState(false)

  const { savedState, setSavedState } = useContext(ArticleContext)
  const { set_isHidden } = useMusic()

  async function fetchDataApi() {
    console.log(savedState)
    try {
      const articleAPI = new ArticleAPI()
      set_isLoading(true)

      const result = await articleAPI.Read_articleFiles(type!, code!, "index.md")

      setMarkdown(result)
      setHeadings(extractHeadings(result)) // 提取標題並存儲到狀態
      setTimeout(() => {
        set_isLoading(false)
      }, 100)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    set_isHidden(true) // 當組件加載時隱藏
    return () => {
      set_isHidden(false) // 當組件卸載時執行
    }
  }, [])
  // 根據 `type` 確定返回路徑
  const backPath = type === "information-tech" || type === "artificial-intelligence" ? "/articles" : type === "sci-fi" ? "/movies" : "/"
  const [isTocVisible, setIsTocVisible] = useState(false) // 狀態來控制目錄是否顯示

  const location = useLocation()
  useEffect(() => {
    console.log(location.pathname) // 獲取當前路徑
    console.log(type=== "information-tech" )
    console.log(    backPath)


    fetchDataApi()
  }, [])

  return (
    <div>
      <div className="markdown-container text_RWD">
        <Link style={{ color: "black", maxWidth: "100px" }} to={backPath}>
          <Button color="inherit">
            <ArrowBackIcon />
            <p>返回</p>
          </Button>
        </Link>
        <Button style={{ backgroundColor: "rgb(155, 155, 155)" }} variant="contained" onClick={() => setIsTocVisible(!isTocVisible)} sx={{ paddingLeft: "0px", position: "fixed", top: "10px", right: "-30px", zIndex: 1000 }}>
          <MenuOpenIcon />
        </Button>
        {/* 目錄容器 */}

        <SimpleTransitionsSlide touch timeout={[1000, 400, 250]} transition={"left"} isActive={isTocVisible} position_row={"end"} position_column={"start"}>
          <Box className="table-of-contents">
            <ul>
              {headings.map((heading, index) => (
                <li key={index} style={{ marginLeft: `${(heading.level - 1) * 20}px` }}>
                  <a
                    href={`#heading-${heading.text.replace(/\s+/g, "-").toLowerCase()}`}
                    onClick={(e) => {
                      e.preventDefault() // 防止默認跳轉
                      setIsTocVisible(!isTocVisible)
                      const element = document.getElementById(`heading-${heading.text.replace(/\s+/g, "-").toLowerCase()}`)
                      element?.scrollIntoView({ behavior: "smooth", block: "start" })
                    }}
                  >
                    {heading.text}
                  </a>
                </li>
              ))}
            </ul>
          </Box>
        </SimpleTransitionsSlide>

        {isLoading ? (
          <SkeletonArticle />
        ) : (
          <ReactMarkdown
            remarkPlugins={[remarkGfm, remarkMath]}
            rehypePlugins={[rehypeKatex]}
            components={{
              h1({ children, ...props }) {
                const text = String(children)
                const id = `heading-${text.replace(/\s+/g, "-").toLowerCase()}`
                return (
                  <h1 id={id} {...props}>
                    {children}
                  </h1>
                )
              },
              h2({ children, ...props }) {
                const text = String(children)
                const id = `heading-${text.replace(/\s+/g, "-").toLowerCase()}`
                return (
                  <h2 id={id} {...props}>
                    {children}
                  </h2>
                )
              },
              h3({ children, ...props }) {
                const text = String(children)
                const id = `heading-${text.replace(/\s+/g, "-").toLowerCase()}`
                return (
                  <h3 id={id} {...props}>
                    {children}
                  </h3>
                )
              },
              code({ node, inline, className, children, ...props }: any) {
                const match = /language-(\w+)/.exec(className || "")

                return !inline && match ? (
                  <SyntaxHighlighter style={dracula} PreTag="div" language={match[1]} {...props}>
                    {String(children).replace(/\n$/, "")}
                  </SyntaxHighlighter>
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                )
              },
            }}
          >
            {markdown}
          </ReactMarkdown>
        )}
      </div>
      <Footer />
    </div>
  )
}

export default ArticlePage
