export async function MovieAPI(search?: string) {
  try {
    // 初始化 queryString 陣列
    const queryParams: string[] = []

    // 將 search 加入查詢參數（若有）
    if (search && search.trim() !== "") {
      queryParams.push(`search=${encodeURIComponent(search)}`)
    }

    // 將查詢參數拼接為 query string
    const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : ""

    // const response = await fetch(`http://192.168.0.104:3600/api/movies${queryString}`, {
      const response = await fetch(`https://r036-server.synology.me/api/movies${queryString}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json", // Set the correct Content-Type
        // Authorization: `Bearer ${process.env.API_KEY}`,
      },
    })
    if (!response.ok) {
      throw new Error(`Failed to read movie. Status: ${response.status}`)
    }
    const result = await response.json()
    console.log(result)
    return result
  } catch (error) {
    console.log(error)
    throw error; // 確保異常能夠傳遞到 fetchDataApi
  }
}
