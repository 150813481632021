import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import React, { useEffect, useState } from "react"
import Backdrop from "../../@R036/R_Base/backdrop/backdropBase"
import { useMusic } from "./MusicContext"
import "./spotify.css"

const SpotifyEmbed: React.FC = () => {
  const [embedUrl, setEmbedUrl] = useState<string>("")
  const [metaData, setMetaData] = useState<any>(null) // 用于存储额外的JSON数据

  const { musicId, set_MusicId } = useMusic()
  const { isTurnOn, set_isTurnOn, isPlaying, set_isPlaying, isHidden } = useMusic()

  useEffect(() => {
    if (musicId) {
      const url = `https://open.spotify.com/embed/playlist/${musicId}?utm_source=generator&theme=0`
      setEmbedUrl(url)

      const oEmbedUrl = `https://open.spotify.com/oembed?url=https://open.spotify.com/playlist/${musicId}`
      fetch(oEmbedUrl)
        .then((response) => response.json())
        .then((data) => setMetaData(data))
        .catch((error) => console.error("Error fetching oEmbed data:", error))
    }
  }, [musicId])

  return (
    <>
   
        <div className="music_box" >
          {/* 固定顯示的按鈕 */}
          <div style={isHidden?{visibility: "hidden"}:{}} className={isHidden?"music_button_hidden":"music_button"} onClick={() => set_isTurnOn(!isTurnOn)}>
            <div style={{ position: "absolute", top: "14px", zIndex: 1011, height: "100%", color: "white" }}>
              <img style={{ width: "35px", height: "35px" }} src={"./../@R036_images/icon/spotify.svg"} />
            </div>
            <div className={`glowing-circle`}>
              <div className="circle-core"></div>
            </div>
          </div>

          <Backdrop
            open={isTurnOn}
            set_open={() => {
              set_isTurnOn(!isTurnOn)
            }}
          >
            {/* 控制顯示的 UI 區域 */}
            <div className={`music_UI ${isTurnOn ? "show" : ""}`}>
              <iframe src={embedUrl} width="100%" height="100%" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" />

              <ToggleButtonGroup orientation="vertical" value={musicId} exclusive onChange={(event, nextView) => nextView && set_MusicId(nextView)}>
                <ToggleButton value="47MGQkF1JodqkYltWkWC4d" aria-label="list">
                  <span style={{ marginLeft: "10px" }}>日常</span>
                </ToggleButton>
                <ToggleButton value="0Qi9XdW1aLVAFx87C8nvff" aria-label="module">
                  <span style={{ marginLeft: "10px" }}>微醺</span>
                </ToggleButton>
                <ToggleButton value="6BMsjkh7fiTYBQcM5Mkpak" aria-label="quilt">
                  <span style={{ marginLeft: "10px" }}>古典</span>
                </ToggleButton>
                <ToggleButton value="2wRGm87noAM7vPFxKLGDS1" aria-label="quilt">
                  <span style={{ marginLeft: "10px" }}>叛客</span>
                </ToggleButton>
                <ToggleButton value="0J6tOwRpy4lFpFqnYD5VxI" aria-label="quilt">
                  <span style={{ marginLeft: "10px" }}>自由</span>
                </ToggleButton>
                <ToggleButton value="1MLmDkCJ0w4DrbgVgZheSS" aria-label="quilt">
                  <span style={{ marginLeft: "10px" }}>藍調</span>
                </ToggleButton>
                <ToggleButton value="7pm65z0O9tWSJEd0cEFkbf" aria-label="quilt">
                  <span style={{ marginLeft: "10px" }}>都會</span>
                </ToggleButton>
                {/* 可以繼續添加更多 ToggleButton */}
              </ToggleButtonGroup>
            </div>
          </Backdrop>
        </div>

    </>
  )
}

export default SpotifyEmbed
