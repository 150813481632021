import SearchIcon from "@mui/icons-material/Search"
import { Chip, IconButton, InputBase } from "@mui/material"
import React, { useState } from "react"
import { R_Display } from "../R_Style/R_Style"

type SearchBaseProps = {
  placeholder?: string
  search: string
  set_search: (search: string) => void
  triggerSearch: (search: string) => void
}

const SearchBase: React.FC<SearchBaseProps> = ({  triggerSearch, placeholder }) => {
  const [searchFilter, set_searchFilter] = useState<string>("")
  const [search, set_search] = useState<string>("")

  return (
    <div style={{ ...R_Display("row", "flex-start", "center") }}>
      {searchFilter ? (
        <div style={{}}>
          <IconButton
            type="button"
            sx={
              {
                // paddingRight: "36px",
              }
            }
            aria-label="search"
            onClick={() => {
              triggerSearch(search)
              set_searchFilter(search)
            }}
          >
            <SearchIcon />
          </IconButton>
          <Chip
            style={{
              paddingLeft: "15px",

              backgroundColor: "white",
            }}
            label={searchFilter}
            variant="filled"
            onDelete={() => {
              triggerSearch("")
              set_searchFilter("")
            }}
          />
        </div>
      ) : (
        <>
          <IconButton
            type="button"
            sx={{}}
            aria-label="search"
            onClick={() => {
              triggerSearch(search)
              set_searchFilter(search)
            }}
          >
            <SearchIcon />
          </IconButton>
          <InputBase
            value={search}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                triggerSearch(search)
                set_searchFilter(search)
              }
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              set_search(event.target.value)
            }}
            sx={{
              // ...(search ? { backgroundColor: " rgb(163, 163, 163)" } : { width: "150px" }),
              backgroundColor: " rgb(255, 255, 255)",
              width: "150px",
              transition: "width 0.3s ease-in-out",
              paddingInline: "25px",
              borderRadius: "80px",
              maxWidth: "200px",
              flex: 1,
              "&:hover": {
                backgroundColor: " #f1f1f1",
              },
              "&.Mui-focused": {
                backgroundColor: "#f1f1f1",
                width: "200px",
              },
            }}
            placeholder={placeholder || "搜尋…"}
            inputProps={{ "aria-label": "search" }}
          />
        </>
      )}
    </div>
  )
}

export default SearchBase
