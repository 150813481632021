import React from "react"
import ReactMarkdown from "react-markdown"
import "./PrivacyPolicy.css" // 假設我們使用一個 CSS 文件進行樣式設計
import HideAppBar from "../../@R036/R_Mui/AppBar"
import Footer from "../../@R036/R_Base/Footer/Footer"

const PrivacyPolicy = () => {
  return (
    <>
      <HideAppBar />
      <div className="privacy-policy">
        <header className="privacy-header">
          <h1>使用條款與聲明</h1>
        </header>

        <section className="privacy-content">
          <ReactMarkdown>{markdownContent}</ReactMarkdown>
        </section>

        <div className="privacy-footer">
          <p>
            如果您有任何疑問，請通過 <a href="mailto:r036.software@gmail.com">r036.software@gmail.com</a>
            聯繫我們。
          </p>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPolicy
const markdownContent = `

## 網站著作權與營利聲明  
本網站的所有內容（包括但不限於文章、圖片及其他媒體）均屬原作者所有，並受相關法律保護。未經書面許可，不得以任何形式分發或商業使用本網站內容，不得將內容整篇粘貼並當作自己的文章使用。若用於學習與研究目的，可在合理範圍內引用或參考。

# 非營利取向
本網站為非營利的資訊分享平台，所有內容作為學術討論及興趣交流之用，無營利部分。如有任何內容使用原創作者的材料，已註明來源並符合合理使用原則。  

## 使用的電影海報用途  
本網站所使用的電影海報僅作為宣傳用途，主要用於撰寫影評文章的封面，以及幫助推廣與電影相關的文學作品。所有海報版權均歸其原權利人所有。

## 隱私權政策  
我們非常重視您的隱私，本網站不會收集您的任何個人資料，您可以放心瀏覽並使用我們的服務。


---

`
