import { animated, useSpring } from "@react-spring/web"
import React, { useEffect } from "react"
import "./loadingPage.css"

interface LoadingPageProps {
  isLoading: boolean
}

const LoadingPage: React.FC<LoadingPageProps> = ({ isLoading }) => {

  useEffect(() => {
    const preventScroll = (e: Event) => e.preventDefault();
  
    if (isLoading) {
      document.addEventListener("scroll", preventScroll, { passive: false });
      document.addEventListener("wheel", preventScroll, { passive: false });
      document.addEventListener("touchmove", preventScroll, { passive: false });
    } else {
      document.removeEventListener("scroll", preventScroll);
      document.removeEventListener("wheel", preventScroll);
      document.removeEventListener("touchmove", preventScroll);
    }
  
    return () => {
      document.removeEventListener("scroll", preventScroll);
      document.removeEventListener("wheel", preventScroll);
      document.removeEventListener("touchmove", preventScroll);
    };
  }, [isLoading]);

  const spring = useSpring({
    from: { progress: 0 },
    to: { progress: isLoading ? 36 : 0 }, // Ends at 36
    config: {
      mass: 2,
      friction: 10,
      tension: 10,
    },
  })

  const formatNumber = (value: number) => value.toString().padStart(3, "0") // Ensures 3 digits (e.g., 001)

  if (!isLoading) return null

  return (
    <div className="loadingPage One_theme_number">
      <div className="content text">
        <animated.span className="">{spring.progress.to((value) => formatNumber(Math.floor(value)))}</animated.span>
      </div>
    </div>
  )
}

export default LoadingPage
