import React, { createContext, useContext, useState } from "react";

// 創建 Context
export const ArticleContext = createContext<any>(null);

export const ArticleProvider = ({ children }: { children: React.ReactNode }) => {
  const [savedState, setSavedState] = useState({ scrollY: 0, currentPage: 1 });
  return (
    <ArticleContext.Provider value={{ savedState, setSavedState }}>
      {children}
    </ArticleContext.Provider>
  );
};

// 使用 Context
// export const useArticleState = () => useContext(ArticleContext);
