import { Box, Pagination, Skeleton } from "@mui/material"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import ArticleCard from "../../@R036/R_Asset/Card/ArticleCard/ArticleCard"
import BaseCard from "../../@R036/R_Asset/Card/BaseCard"
import HideAppBar from "../../@R036/R_Mui/AppBar"
import SearchBase from "../../@R036/R_Mui/SearchBase"
import { R_Display } from "../../@R036/R_Style/R_Style"
import ArticleAPI from "../../API/ArticleAPI"
import { MovieAPI } from "../../API/movieAPI"
import "./movieRoom.css"
type movieModel = {
  title: string
  img: string
  date: string
}

export default function MovieRoom() {
  const [isLoading_article, set_isLoading_article] = useState(false)
  const [isLoading_movies, set_isLoading_movies] = useState(false)

  const [movies, set_movies] = useState<Array<movieModel>>([])
  const [articles, set_articles] = useState<Array<any>>([])

  const [pagination, set_pagination] = useState(10)
  const [currentPage, set_currentPage] = useState<number>(1)
  const [search, set_search] = useState("")
  const [search_focus, set_search_focus] = useState("")

  const articleAPI = new ArticleAPI()

  // 初始仔入
  async function fetchData_articles(search: any = "", currentPage: number = 1) {
    try {
      set_isLoading_article(true)
      //TODO 為查詢資料篩選後總數
      const articlesAll: any = await articleAPI.Read_articles("sci-fi", "", search)
      const numberPage = Math.ceil(articlesAll?.length / 10)
      set_pagination(numberPage)
      console.log("總資料數目: " + articlesAll?.length, "總頁數: " + numberPage)

      //TODO 獲取分頁資料
      const articles = await articleAPI.Read_articles("sci-fi", "", search, currentPage)
      set_articles(articles)
      console.log(articles)

      setTimeout(() => {
        set_isLoading_article(false)
      }, 300) //故意延遲
    } catch (error) {
      console.error("Error loading data:", error)
    }
  }

  async function fetchData_movies(search: any = "") {
    try {
      set_isLoading_movies(true)
      const data = await MovieAPI(search) // 假設 MovieAPI 是一個返回數據的函數
      set_movies(data) // 更新 mainData 狀態

      setTimeout(() => {}, 300) //故意延遲
    } catch (error) {
      console.error("Error loading data:", error)
    } finally {
      set_isLoading_movies(false)
    }
  }
  //初始載入資料
  useEffect(() => {
    fetchData_articles()
    fetchData_movies()
  }, [])

  useEffect(() => {
    if (search_focus) {
      set_search(search_focus)
    }
  }, [search_focus])

  const triggerSearch = (searchTerm: string) => {
    console.log("搜尋關鍵字:", searchTerm)
    set_search(searchTerm)
    fetchData_articles(searchTerm, 1)
    fetchData_movies(searchTerm)
  }

  return (
    <>
      <HideAppBar />
      <div className="movieRoom">
        <div className="movies-left-right-frame">
          <div className="left-side">
            <>
              <div className="topAD"></div>

              <div style={{ left: 0, marginTop: "50px" }}>
                <SearchBase placeholder={"搜尋一部科幻"} search={search} set_search={set_search} triggerSearch={triggerSearch} />
              </div>

              <div style={{ ...R_Display("row", "space-between", "center"), marginInline: "20px" }}>
                <h3 style={{ flex: 1, width: "100%" }}>影視</h3>
                <Link key={1} style={{ flex: 1 }} className="seachAll" to={"all"}>
                  <h3>展開</h3>
                </Link>
              </div>
              <div className="gridBox">
                {isLoading_movies
                  ? Array.from({ length: 5 }).map((_, index) => <Skeleton key={index} variant="rounded" width={130} height={180} style={{ borderRadius: "10px", margin: 15 }} />)
                  : movies?.map((one, index) => (
                      <div key={index} style={{ margin: "15px" }} onClick={() => set_search_focus(one.title)}>
                        <BaseCard isSelected image={one.img} width={130} height={180} />
                        <div className={"One_theme_number"} style={{ fontSize: "14px", color: "gray", marginBlock: "10px" }}>
                          {new Date(one.date).toLocaleDateString("zh-TW", { year: "numeric", month: "2-digit" })}
                        </div>
                        <p>{one.title}</p>
                      </div>
                    ))}
              </div>
            </>
          </div>
          <div className="right-side">
            <div style={{ ...R_Display("row", "flex-start", "center"), marginInline: "20px" }}>
              <h3 style={{ marginRight: "15px" }}>影視</h3>
            </div>
            {isLoading_article ? Array.from({ length: 5 }).map((_, index) => <Skeleton key={index} variant="rounded" height={160} style={{ borderRadius: "10px", marginBottom: 35 }} />) : articles?.map((n, index) => <ArticleCard key={index} data={n} />)}
            <div style={{ marginBlock: "30px", ...R_Display("column", "center", "center") }}>
              <Pagination
                count={pagination}
                shape="rounded"
                onChange={(event, page) => {
                  console.log("選擇頁數" + page)
                  set_currentPage(page)
                  fetchData_articles(search, page)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
