import { useEffect, useState } from "react"

interface BeforeInstallPromptEvent extends Event {
 
  readonly platforms: Array<string>

  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed"
    platform: string
  }>

  prompt(): Promise<void>
}

export function PWA_DownloadEvent(): any | undefined{
  const [prompt, setState] = useState<BeforeInstallPromptEvent | null>(null)

  const promptToInstall = async () => {
    console.log(prompt)

    if (prompt) {
      await prompt.prompt();
    } else {
      // throw new Error('Tried installing before the browser sent "beforeinstallprompt" event');
      return false
    }
  };

  const ready = (e: BeforeInstallPromptEvent) => {
    e.preventDefault()
    setState(e)
  }
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", ready as any)
    return () => {
      window.removeEventListener("beforeinstallprompt", ready as any)
    }
  }, [])

  return promptToInstall
}
