export type article_model = {
  code: string
  title: string
  date: Date
  tag: any
  cover: string
  type: string
}

export type page_module = { isPage: boolean; pageNumber?: number }

export default class ArticleAPI {
  async Read_articles(type?: string, filter?: string, search?: string, page?: number) {
    try {
      // 初始化 queryString 陣列
      const queryParams: string[] = []

      // 將 filter 加入查詢參數（若有）
      if (filter && filter.trim() !== "" && filter !== "全部") {
        queryParams.push(`filter=${encodeURIComponent(filter)}`)
      }

      // 將 search 加入查詢參數（若有）
      if (search && search.trim() !== "") {
        queryParams.push(`search=${encodeURIComponent(search)}`)
      }

      // 將 page 加入查詢參數（若有）
      if (page) {
        queryParams.push(`page=${encodeURIComponent(page)}`)
        console.log("選擇頁數" + page)
      }

      // 將查詢參數拼接為 query string
      const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : ""

      // 拼接完整的 URL
      // const url = `http://localhost:3600/api/articles/${type}${queryString}`

      let url = ""
      if (type !== undefined) {
        url = `https://r036-server.synology.me/api/articles/${type}${queryString}`
      } else {
        url = `https://r036-server.synology.me/api/articles`
      }

      // 發送請求，附加 queryString 參數
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json", // Set the correct Content-Type
        },
      })
      if (!response.ok) {
        throw new Error(`Failed to delete article. Status: ${response.status}`)
      }

      const result = await response.json()
      // console.log(result)
      return result
    } catch (error) {
      console.log(error)
      throw error // 確保異常能夠傳遞到 fetchDataApi
    }
  }

  async Read_articleFiles(type: string, code: string, file: string) {
    try {
      // 構造文件路徑。注意，public 文件夾內的文件在 React 中可以通過相對 URL 獲取
      // const path = `http://localhost:3600/api/articles/${type}/${code}/${file}`
      const path = `https://r036-server.synology.me/api/articles/${type}/${code}/${file}`

      // 使用 fetch 來獲取靜態資源文件
      const response = await fetch(path, {})

      if (!response.ok) {
        throw new Error(`Failed to fetch file. Status: ${response.status}`)
      }

      // 獲取文件的文本內容
      const data = await response.text()
      // console.log(data);

      return data
    } catch (error) {
      console.error("Error reading file:", error)
      throw error // 確保異常能夠傳遞到 fetchDataApi
    }
  }
}
