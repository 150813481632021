import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion"
import DrawerList from "../@R036/R_Base/Drawer/DrawerList"

type Props = {
  active: Function
}
export default function DrawerListComponet(props: Props) {
  return (
    <DrawerList
      // topbar={<Button_base active={() => props.active() } close_model ></Button_base>}
      list={[
        { title: "首頁", scr: "/", icon: <img style={{ marginRight:"15px",width: "25px", height: "25px" }} src={"./../@R036_images/icon/home.svg"} /> },
        { title: "技術文章", scr: "/articles", icon: <img style={{ marginRight:"15px",width: "25px", height: "25px" }} src={"./../@R036_images/icon/code.svg"} /> },
        // { title: "系統設計", scr: "/systemDesign", icon: <img style={{ marginRight:"15px",width: "25px", height: "25px" }} src={"./../@R036_images/icon/git.svg"} /> },
        { title: "科幻影視", scr: "/movies", icon: <img style={{ marginRight:"15px",width: "25px", height: "25px" }} src={"./../@R036_images/icon/movies.svg"} /> },
      ]}
    />
  )
}
