import { useState, useEffect, CSSProperties } from "react"
import React from "react"

type DynamicTextBoxProps = {
  text: string
  style?: CSSProperties
  className?: string
  isOpen: boolean
}

export default function DynamicTextBox(Props: DynamicTextBoxProps) {
  // const [textaa, setaaText] = useState(false);
  const [text, setText] = useState("")
  const inputText = Props.text
  // setaaText(Props.isOpen)

  useEffect(() => {
    let currentIndex = 0
    const interval = setInterval(() => {
      if (currentIndex <= inputText.length) {
        setText(inputText.slice(0, currentIndex))
        currentIndex++
      } else {
        clearInterval(interval)
      }
    }, 100) // 100ms 间隔显示下一个字母

    // 清除 interval，避免内存泄漏
    return () => {
      clearInterval(interval)
    }
  }, [Props.isOpen]) // useEffect 仅在组件挂载后执行一次

  return (
    <p style={Props.style}>
      <span className={Props.className}>{text}</span>|
    </p>
  )
}
