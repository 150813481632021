import React from "react";
import "./ArticleCard.css";
import { Link } from "react-router-dom";
import { article_model } from "../../../../API/ArticleAPI";

type Props = {
  data : article_model,
  style?: React.CSSProperties;
};



export default function ArticleCard({ data, style = {} }: Props) {
  const handleClick = () => {
    window.scrollTo(0, 0); // 滾動到頁面頂部
  };
  return (
    <section style={style} className="ArticleCard">
      <Link onClick={handleClick} to={`/articles/${data.type+"/"+data.code}`}>
          <div className="Article_img" style={{ backgroundImage: `url('${data.cover}')` }} />
          <div className="content">
            <p className="title">{data.title}</p>
            <p className="date One_theme_number">{new Date(data.date).toLocaleDateString('zh-TW')}</p>
            <div className="tag-box">
              {data.tag.map((n:any, index:any) => (
                <p key={index} className="tag">
                  {n}
                </p>
              ))}
            </div>
          </div>
      </Link>
    </section>
  );
}
