import { animated, useChain, useSpring, useSpringRef, useTrail } from "@react-spring/web"
import * as React from "react"

import styles from "./noteGrid.module.css"

const STROKE_WIDTH = 0.3

export default function NoteGrid({ isAnimationActive }: any) {
  const gridApi = useSpringRef()

  const [width, setWidth] = React.useState(window.innerWidth / 2)
  const [height, setHeight] = React.useState(window.innerHeight * 0.9)

  const springConfig = {
    tension: 150, // 彈性高，動畫加速 (彈性)
    friction: 55, // 摩擦力較低，動畫結束時較慢停止 (模插立)
    mass: 1.5, // 有點質量，讓動畫看起來不會太輕 (緩慢)
  }
  // 監聽視窗大小變化
  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth / 2);
      setHeight(window.innerHeight * 0.9);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const numLines = Math.floor((width + height) / 30) // 根據高度計算線條數量
  const gridSprings = useTrail(numLines, {
    ref: gridApi,
    from: {
      x2: 0,
      y2: 0,
    },
    to: isAnimationActive ? { x2: width, y2: height } : { x2: 0, y2: 0 },
    config: {   tension: 170, friction: 26,mass:1 },
  })


  const { lineColor } = useSpring({
    from: { lineColor: 'var(--line0)' },
    to: [
      { lineColor: 'var(--line0)' },
      { lineColor: 'var(--line1)' },
    ],
    config: {
      tension: 40, friction: 20
    },
    loop: { reverse: true },
  });
  

  const boxApi = useSpringRef()
  useChain([gridApi, boxApi], [0, 1], 1500)

  return (
    <animated.div className={styles["background-container"]}>
      <svg viewBox={`0 0 ${width} ${height}`}>
        {/* 定義漸變 */}
        <g>
          {gridSprings.map(({ x2 }, index) => (
            <animated.line x1={0} y1={index * 32} x2={x2} y2={index * 32} key={index} strokeWidth={STROKE_WIDTH} stroke="currentColor"/>
          ))}
          {gridSprings.map(({ y2 }, index) => (
            <animated.line x1={index * 32} y1={0} x2={index * 32} y2={y2} key={index} strokeWidth={STROKE_WIDTH} stroke="currentColor" />
          ))}
        </g>
      </svg>
    </animated.div>
  )
}
