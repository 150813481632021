import PersonPinIcon from "@mui/icons-material/PersonPin"
import { Box, Button } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import DynamicTextBox from "../../@R036/R_Asset/DynamicTextBox"
import EventDialogBase from "../../@R036/R_Base/Dialog/EventDialogBase"
import Footer from "../../@R036/R_Base/Footer/Footer"
import SimpleTransitionsSlide from "../../@R036/R_Mui/TransitionsSlide"
import LoadingPage from "../../@R036/R_Spring/loadingPage"
import { RWD_Style_Listener, R_Display, Scroll_listen } from "../../@R036/R_Style/R_Style"
import "../../@R036/R_Style/R_Style.css"
import "./home.css"

import HideAppBar from "../../@R036/R_Mui/AppBar"
import NoteGrid from "./noteGrid/noteGrid"
import { PWA_DownloadEvent } from "./useAddToHomescreenPrompt"
import { useTrail, a } from "@react-spring/web"
import ArticleAPI from "../../API/ArticleAPI"
import { MovieAPI } from "../../API/movieAPI"
import { title } from "process"

function App() {
  const [isLoading, set_isLoading] = React.useState(false)
  const [globalData, set_globalData] = useState<Array<any>>([])
  const articleAPI = new ArticleAPI()

  // 初始仔入
  async function fetchData_articles(search: any = "", currentPage: number = 1) {
    try {
      //TODO 為查詢資料篩選後總數
      const IT = await articleAPI.Read_articles("information-tech")
      const sciFi = await articleAPI.Read_articles("sci-fi")
      const movies = await MovieAPI() // 假設 MovieAPI 是一個返回數據的函數
      const combinedData = [
        { count: IT.length, title: "技術文章" },
        { count: sciFi.length, title: "科幻短評" },
        { count: movies.length, title: "影視收錄" },
      ]

      // Update state with the combined data
      set_globalData(combinedData)
    } catch (error) {
      console.error("Error loading data:", error)
    }
  }

  //TODO 初始化載入
  useEffect(() => {
    fetchData_articles()
    if (sessionStorage.getItem("originLoading") === null) {
      set_isLoading(true)
      // simulateLoading() // 模擬載入
      sessionStorage.setItem("originLoading", "true")

      setTimeout(() => {
        set_isActive_PWA_Event(true)
      }, 1000)
    }
    setTimeout(() => {
      set_isLoading(false)
    }, 3000)
  }, []) // 依賴陣列為空，表示只在組件首次渲染時執行

  //TODO 滾動監聽
  const [dynamicTextState, set_dynamicTextState] = React.useState(false)
  const [blockOne, set_blockOne] = useState(false)
  const [blockOne_2, set_blockOne_2] = useState(false)
  const [blockTwo, set_blockTwo] = useState(false)
  console.log(blockOne)
  useEffect(() => {
    Scroll_listen([
      { state: dynamicTextState, set_state: set_dynamicTextState, y: 200 },
      { state: blockOne, set_state: set_blockOne, y: 200 },
      { state: blockOne_2, set_state: set_blockOne_2, y: 800 },
      { state: blockTwo, set_state: set_blockTwo, y: 1100 },
    ])
  }, []) // 空数组表示只在组件挂载和卸载时执行

  //TODO PWA 安裝
  const [isActive_PWA_Event, set_isActive_PWA_Event] = React.useState(false)
  const Click_PWA_DownloadEvent = PWA_DownloadEvent()

  // TODO RWD 監聽器
  const [xs, set_xs] = useState(false)
  const [md, set_md] = useState(false)
  useEffect(() => {
    RWD_Style_Listener(set_xs, set_md)
  }, [])

  return (
    <div className="home text_RWD">
      <HideAppBar isWhite={true} />
      <LoadingPage isLoading={isLoading} />

      <div className="home_one">
        <div className="content" style={{ ...R_Display("column", "center", "center") }}>
          {isLoading ? (
            <></>
          ) : (
            <>
              <div className={`float-in ${dynamicTextState ? "" : "show"}`}>
                {/* <DynamicTextBox className="One_theme_number" style={{ color: "white", fontSize: "12.2em", margin: 0 }} text={`036`} isOpen={dynamicTextState} /> */}
                <span className="One_theme_number" style={{ fontSize: "10rem", color: "rgba(255, 255, 255, 0.15)" }}>
                  036
                </span>
                <DynamicTextBox key={1} className="" style={{ color: "white", fontSize: ".8rem", fontWeight: 300, letterSpacing: "1px" }} text={"「在現代科學的浪潮中，我們的理性根植於人文學科的土壤」"} isOpen={dynamicTextState} />
                <Link to={"/profile"} style={{ marginTop: "20px" }}>
                  <Button onClick={() => {}} variant="outlined" style={{ color: "white", borderColor: "white", marginTop: "20px" }} href="">
                    <PersonPinIcon fontSize="small" sx={{ marginRight: "5px" }} />
                    關於開發者
                  </Button>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
      <div style={{ position: "absolute" }}>
      </div>
        <NoteGrid isAnimationActive={blockOne} />
      <div id="home_two" style={{ ...R_Display("column", "space-evenly", "center") }}>
        <div className="home_two_text" style={{ ...R_Display("column", "center", "center") }}>
          <AnimatedContainer isActive={blockOne}>
            <h2 className="Font_Family_NoteSans title">
              研發室<span className="One_theme_number">036</span>號|
            </h2>
            <p style={{ fontSize: "18px", color: "rgb(255, 255, 255,1)" }}>是一個理念象徵，一個專注在資訊科技與人文的地方</p>
            <p style={{ fontSize: "16px", color: "rgb(255, 255, 255,0.5)", marginTop: "20px" }}>象徵著不斷更新的版本，在這個數位時代，生活就像軟體一樣，不斷進行更新，每一次經驗、每一個挑戰，都為生活帶來了新的修補與升級</p>
            <img style={{ marginTop: "40px" }} src={"/@R036_images/home/R036-Software-Life-Banner.svg"}></img>
          </AnimatedContainer>
        </div>
        <div style={{ width: "100%" }}>
          <Box display="flex" justifyContent="space-around" alignItems="flex-end">
            {globalData.map((n, index) => (
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <AnimatedContainer isActive={blockOne_2} style={{ ...R_Display("column", "center", "center") }}>
                  <div style={{ height: "100%", fontSize: "5rem", color: "rgb(255, 255, 255)" }} className="One_theme_number">
                    {n.count}
                  </div>
                  <div style={{ color: "rgb(213, 213, 213)", height: "100%", fontSize: "0.8rem" }}> {n.title}</div>
                </AnimatedContainer>
              </Box>
            ))}
          </Box>
        </div>
      </div>
      {/* 3-1區塊 */}

      <div id="home_three">
        <div className="content Font_Family_NoteSans">
          <AnimatedContainer isActive={blockTwo}>
            <h2 className="Font_Family_NoteSans title">技術文章 </h2>
            <p style={{ fontSize: "18px", color: "rgb(255, 255, 255,1)" }}>軟體技術寫作</p>
            <p style={{ fontSize: "16px", color: "rgb(255, 255, 255,0.5)", marginTop: "20px" }}>在軟體工程技術精進的同時，也不忘回饋軟體技術圈</p>
          </AnimatedContainer>
        </div>

        <div className="content Font_Family_NoteSans">
          <AnimatedContainer isActive={blockTwo}>
            <h2 className="Font_Family_NoteSans title">
              系統設計 <span className="One_theme_number"> </span>{" "}
            </h2>
            <p style={{ fontSize: "18px", color: "rgb(255, 255, 255,1)" }}>系統學設計</p>
            <p style={{ fontSize: "16px", color: "rgb(255, 255, 255,0.5)", marginTop: "20px" }}>透過圖像關係來構建系統思維及關聯，注重所有領域的知識關聯性，建構一個有用的系統模型</p>
          </AnimatedContainer>
        </div>

        <div className="content Font_Family_NoteSans">
          <AnimatedContainer isActive={blockTwo}>
            <h2 className="Font_Family_NoteSans title">
              科幻文學 <span className="One_theme_number"></span>{" "}
            </h2>
            <p style={{ fontSize: "18px", color: "rgb(255, 255, 255,1)" }}>致力於推廣科幻文學</p>
            <p style={{ fontSize: "16px", color: "rgb(255, 255, 255,0.5)", marginTop: "20px" }}>特別設立了科幻專欄網站，我們相信，科技的進步離不開豐富的想像力，而科幻正是激發創新思維、探索未來世界的有趣的文學形式</p>
          </AnimatedContainer>
        </div>
      </div>

      {/* <PWA /> */}
      <SimpleTransitionsSlide touch timeout={[1000, 500, 500]} transition={"up"} isActive={isActive_PWA_Event} position_row={"start"} position_column={"end"}>
        <div style={xs ? { zIndex: 10, width: "100vw", fontWeight: 400, boxShadow: "0 0 0" } : md ? { zIndex: 10, width: "300px", boxSizing: "border-box", fontWeight: 400 } : {}}>
          <EventDialogBase
            sx={xs ? { borderRadius: 0, boxShadow: "0 0 0" } : { margin: "20px" }}
            isActive={isActive_PWA_Event}
            set_isActive={set_isActive_PWA_Event}
            event={() => {
              Click_PWA_DownloadEvent()
            }}
            text_content={`安裝行動應用`}
            text_s_content={`使用 Google 瀏覽器來獲得最佳體驗`}
            Button_ok="哪次不同意"
            Button_cancel="先不要"
          />
        </div>
      </SimpleTransitionsSlide>

      <Footer />
    </div>
  )
}
const AnimatedContainer = ({ children, isActive, style }: any) => {
  const containerRef = useRef<any>(null)

  useEffect(() => {
    const elements = containerRef.current.children

    if (isActive) {
      // 添加动画效果
      Array.from(elements).forEach((el: any, index) => {
        setTimeout(() => {
          el.classList.add("show")
        }, index * 300)
      })
    } else {
      // 移除动画效果
      Array.from(elements).forEach((el: any) => {
        el.classList.remove("show")
      })
    }
  }, [isActive]) // 监听 isActive 状态

  return (
    <div ref={containerRef} className="animated-container" style={style}>
      {React.Children.map(children, (child) => React.cloneElement(child, { className: `${child.props.className || ""} float-right` }))}
    </div>
  )
}

export default App
