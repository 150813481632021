import { unified } from "unified";
import remarkParse from "remark-parse";

export type Heading = {
  level: number;
  text: string;
}

export function extractHeadings(markdown: any): Heading[] {
  const headings: Heading[] = [];

  unified()
    .use(remarkParse)
    .parse(markdown)
    .children.forEach((node: any) => {
      if (node.type === "heading") {
        const text = node.children
          .filter((child: any) => child.type === "text")
          .map((child: any) => child.value)
          .join("");

        headings.push({ level: node.depth, text });
      }
    });

  return headings;
}
