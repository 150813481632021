import React from "react";
import "./TopBar.css";
import { Link, useLocation } from "react-router-dom";

type TopBarProps = {
  title_List: Array<{ title: string, scr: string }>
  style?: React.CSSProperties
  isWhite?: boolean
}

export default function TopBar({
  style, 
  title_List, 
  isWhite = false
}:TopBarProps) {

  const location = useLocation(); // 獲取當前路由位置
  return (
      <section id="maintopbar" style={style}>
        <nav style={{}}>
          <ul>
          {title_List.map((n, index) => {
            const isActive = location.pathname === n.scr; // 判斷是否為當前路由

            return (
              <li key={index} className={isWhite ? "white_Model" : ""}>
                <Link
                  to={n.scr}
                  className={`${isActive ? "active" : ""}`} // 如果是當前頁面，添加 'active' 類別
                >
                  {n.title}
                </Link>
              </li>
            );
          })}
          </ul>
        </nav>
      </section>
  );
}