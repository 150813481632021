import React, { useEffect, useState } from "react"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { Box, Chip } from "@mui/material"
import ArticleAPI from "../../API/ArticleAPI"

// 定義行數據的類型
interface RowData {
  id: string // 必須作為 DataGrid 的唯一標識符
  uuid: string | null
  code: string
  title: string
  date: string // ISO 日期格式
  tag: string[] // 標籤的數組
  cover: string // 圖片 URL
  type: string
}

const Dashboard: React.FC = () => {
  const articleAPI = new ArticleAPI()

  // 狀態管理
  const [isLoadingArticle, setIsLoadingArticle] = useState(false)
  const [articles, setArticles] = useState<ReadonlyArray<RowData>>([])

  // 從 API 獲取數據
  const fetchDataArticles = async (search: string = "", currentPage: number = 1) => {
    try {
      setIsLoadingArticle(true)
      const articlesAll: RowData[] = await articleAPI.Read_articles()

      console.log(articlesAll)
      // 將數據格式化為 DataGrid 可用的格式
      const formattedArticles = articlesAll.map((article, index) => ({
        id: `${index}`, // 必須有唯一的 ID
        uuid: article.uuid,
        code: article.code,
        title: article.title,
        date: article.date,
        tag: article.tag,
        cover: article.cover,
        type: article.type,
      }))
      setArticles(formattedArticles)
    } catch (error) {
      console.error("Error loading articles:", error)
    } finally {
      setIsLoadingArticle(false)
    }
  }

  // 初始載入資料
  useEffect(() => {
    fetchDataArticles()
  }, [])

  // 列定義
  const columns: GridColDef[] = [
    // { field: "uuid", headerName: "UUID", width: 200 },
    {
      flex: 0,
      field: "code",
      headerName: "Code",
      minWidth: 150,
    },
    { flex:2,field: "title", headerName: "Title" },
    {
      flex: 0,
      field: "date",
      headerName: "Date",
      valueFormatter: (params) => new Date(params.value as string).toLocaleDateString("zh-TW"),
    },
    {
      flex:1,
      field: "tag",
      headerName: "Tags",
      
      renderCell: (params) => (
        <Box>
          {(params.value as string[]).map((tag, index) => (
            <Chip key={index} label={tag} style={{ marginRight: 4 }} />
          ))}
        </Box>
      ),
    },
    {
      flex: 0,
      field: "cover",
      headerName: "Cover",
      width: 120,
      renderCell: (params) => <img src={params.value as string} alt="cover" style={{ width: "100%", borderRadius: 4 }} />,
    },
    // { field: "type", headerName: "Type", width: 150 },
  ]

  return (
    <Box sx={{width: "auto  ", height: "100%" }}>
      <DataGrid
        sx={{

        }}
        rows={articles}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100]}
        loading={isLoadingArticle} // 顯示加載中狀態
      />
    </Box>
  )
}

export default Dashboard