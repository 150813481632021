import { type } from "os"
import React from "react"
import styles from "./arrowDirection.module.css"

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { ReactElement } from "react"

type Arrow_direction = {
  icon: ReactElement | undefined
  deration_ID: string
  style?: React.CSSProperties
}
export default function ArrowDirection(Props: Arrow_direction) {
  const scrollToElement = (id: string) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <a style={Props.style} className={`${styles.arrow}`} onClick={() => scrollToElement(Props.deration_ID)}>
      {Props.icon === undefined ? <KeyboardArrowDownIcon /> : Props.icon}
    </a>
  )
}
