import { Chip } from "@mui/material"
import { useEffect, useState } from "react"
import { R_Display } from "../../@R036/R_Style/R_Style"
import "./TagBar.css"
// 假設 TagFilterProps 類型包含 TagList 和 onChange 函數
interface TagBarProps {
  TagList: string[],
  onChange:Function
}

export function TagBar(Props: TagBarProps) {
  const [checkButton, setCheckButton] = useState(-1) // 用來控制選中的按鈕
  const TagButtonList: Array<React.ReactElement> = []

  // 當網址中的 filter 參數變化時更新 checkButton
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const filterParam = searchParams.get("filter");

    //是否filter
    if (filterParam) {
      //判斷filter是陣列第幾項
      const index = Props.TagList.indexOf(filterParam);
        setCheckButton(index); // 更新選中的按鈕
    }else{
      setCheckButton(0)
    }
  }, [window.location.search, Props.TagList]); // 監控 URL 變化

  // 遍歷 TagList 並生成按鈕
  Props.TagList.map((tag, tagIndex) => {
    const chips = (
      <Chip
        className="tag-button"
        sx={{ margin: "5px" }}
        key={tagIndex}
        label={tag}
        variant={checkButton === tagIndex ? "filled" : "outlined"}
        onClick={() => {
          setCheckButton(tagIndex) // 更新選中的按鈕
          Props.onChange(tag)
         // 更新 URL 查詢參數
         const searchParams = new URLSearchParams(window.location.search);
         searchParams.set("filter", tag); // 設定 filter 參數
         window.history.pushState({}, "", `${window.location.pathname}?${searchParams.toString()}`);
        }}
      />
    )
    TagButtonList.push(chips)
  })

  return <div className="tag-bar" >{TagButtonList.map((n) => n)}</div>
}
