import React, { CSSProperties, useEffect, useState } from "react"
// import {Mycss} from "./MyCss.css";

//最初Bata版 將拋棄
const all_center: CSSProperties = { display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }
const row_center: CSSProperties = { display: "flex", flexDirection: "row", alignItems: "center" }
const colum_center: CSSProperties = { display: "flex", flexDirection: "row", justifyContent: "center" }
const display_row: CSSProperties = { height: "100%", display: "flex", flexDirection: "row" }
const display_colum: CSSProperties = { display: "flex", flexDirection: "column" }

export function R_Display_bata() {
  const Creator = {
    all_center: all_center,
    row_center: row_center,
    colum_center: colum_center,
    display_row: display_row,
    display_colum: display_colum,
  }
  return Creator
}

/**
 * 動態生成 CSS 樣式的函式，用於設置 Flexbox 布局。
 *
 * @param flexDirection - 主軸方向，必須是 "row" 或 "column"。
 * @param justifyContent - 主軸對齊方式，可選值為 "flex-start" | "center" | "flex-end" | "space-between" | "space-around"。
 * @param alignItems - 交叉軸對齊方式，可選值為 "flex-start" | "center" | "flex-end"。
 * @returns 一個包含指定 Flexbox 配置的 CSSProperties 物件。
 */
export function R_Display(flexDirection: "row" | "column", justifyContent?: "flex-start" | "center" | "flex-end" | "space-between" | "space-around" | "space-evenly", alignItems?: "flex-start" | "center" | "flex-end"): CSSProperties {
  return {
    display: "flex",
    flexDirection,
    ...(justifyContent && { justifyContent }),
    ...(alignItems && { alignItems }),
  }
}

//todo 顏色清單
export function R_Color() {
  const Creator = {
    gray: "#6C6C6C",
    gray2: "#F5F5F5",
    // row_center: row_center,
    // colum_center: colum_center,
    // display_row: display_row,
    // display_colum: display_colum
  }
  return Creator
}

//todo R_Positions()
export function R_Positions(position: "up" | "center" | "bottom") {
  let styleResult: React.CSSProperties = {}

  if (position === "up") {
    styleResult = {
      position: "fixed",
      top: 0,
      right: 0,
      ...colum_center,
    }
  } else if (position === "center") {
    styleResult = {
      position: "absolute",
      top: 0,
      ...all_center,
    }
  } else if (position === "bottom") {
    styleResult = {
      position: "fixed",
      bottom: 0,
      // ...colum_center,
    }
  }

  return styleResult
}

//todo 偵測
// export function RWD_Style(): "phone"|"tablet"|"computer"|undefined {
//   let windowWidth = window.innerWidth;
//   if (windowWidth>1024){
//     return "computer"
//   }else if(windowWidth>768){
//     return "tablet"
//   }else if(windowWidth>0){
//     return "phone"
//   }
// }

export function Scroll_listen(props: Array<{ state: boolean; set_state: Function; y: number }>) {
  const handleScroll = () => {
    // 获取滚动距离
    const scrollY = window.scrollY
    // console.log(scrollY)
    // 设置触发滚动效果的阈值

    props.map((n) => {
      //if 避免重複渲染State
      if (n.state === true) {
        //當滾動到設定值時開啟
        n.set_state(scrollY > n.y)
      } else if (n.state === false) {
        n.set_state(scrollY > n.y)
      }
    })
  }
  // 监听滚动事件
  window.addEventListener("scroll", handleScroll)
  // 清理事件监听，避免内存泄漏
  return () => {
    window.removeEventListener("scroll", handleScroll)
  }
}

export function RWD_Style_Listener(set_xs?: Function, set_md?: Function, set_lg?: Function) {
  const handleResize = () => {
    const windowWidth = window.innerWidth
    let RWD = ""
    if (set_xs) {
      set_xs(windowWidth < 768)
      RWD = "xs"
    }
    if (set_md) {
      set_md(windowWidth > 768)
      RWD = "md"
    }
    if (set_lg) {
      set_lg(windowWidth > 1024)
      RWD = "lg"
    }
    console.log("視窗大小:" + windowWidth + RWD)
  }

  handleResize()
  // 監聽視窗大小變化事件
  window.addEventListener("resize", handleResize)
  // 清理事件監聽器
  return () => {
    window.removeEventListener("resize", handleResize)
  }
}
