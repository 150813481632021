import { createTheme, outlinedInputClasses } from "@mui/material"

export const Theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          fontFamily: "Noto Sans TC,sans-serif;",
          boxShadow: "none", // 取消box陰影
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: "Noto Sans TC,sans-serif;",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontFamily: "Monomaniac One, sans-serif;",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Noto Sans TC,sans-serif;",
        },
      },
    },

    MuiToggleButton: {
      styleOverrides: {
        root: {
          width: "100%", // 讓按鈕填滿容器
          height: "100%",
          display: "flex",
          padding: "10px",
          color: "white",
          borderBottom: "2px solid #ffffff", // 下邊框
          justifyContent: "start",
          textOverflow: "ellipsis", // 超過文字時顯示省略號
          whiteSpace: "nowrap", // 防止文字換行
          overflow: "hidden", // 隱藏溢出的文字
          "&.Mui-selected": {
            color: "black",
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "white",
            },
          },
        },
      },
    },
 
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       fontFamily: "Noto Sans TC,sans-serif;",
    //       "& .MuiInputBase-root": {
    //         fontFamily: "Noto Sans TC,sans-serif;",

    //       },
    //     },
    //   },
    // },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     notchedOutline: {
    //       borderColor: "var(--TextField-brandBorderColor)",
    //     },
    //     root: {
    //       [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
    //         borderColor: "var(--TextField-brandBorderHoverColor)",
    //       },
    //       [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
    //         borderColor: "var(--TextField-brandBorderFocusedColor)",
    //       },
    //     },
    //   },
    // },
  },
})
