import React, { createContext } from "react"
import ReactDOM from "react-dom/client"
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom"
import "./@R036/R_Style/R_FontTheme.css"
// import BackSystem from "./Page/Dashboard/BackSystem"
import { MusicProvider } from "./Component/spotify_server/MusicContext"
import SpotifyEmbed from "./Component/spotify_server/spotify"
import Article from "./Page/article/article"
import ArticlePage from "./Page/article/articlePage"
import ErrorPage from "./Page/error/error-page"
import Home from "./Page/home/home"
import MovieRoom from "./Page/movie/movieRoom"
import MovieRoomAll from "./Page/movie/movieRoomAll"
import ProfilePage from "./Page/profile/ProfilePage"
import SystemDesignHome from "./Page/design/systemDesignHome"
import "./index.css"
import { ThemeProvider } from "@mui/material"
import { Theme } from "./@R036/R_Mui/Theme"
import { ArticleProvider } from "./Page/article/articleContext"
import Dashboard from "./Page/dashboard/dashboard"
import PrivacyPolicy from "./Page/about/privacyPolicy"

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Navigate to="/home" />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/home",
      element: <Home />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/articles",
      element: <Article />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/articles/:type/:code",
      element: <ArticlePage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/profile",
      element: <ProfilePage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/movies",
      element: <MovieRoom />,
      // children: [{ path: "all", element: <MovieRoomAll /> }],
      errorElement: <ErrorPage />,
    },
    {
      path: "/movies/all",
      element: <MovieRoomAll />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/privacyPolicy",
      element: <PrivacyPolicy />,
      errorElement: <ErrorPage />,
    },

    // {
    //   path: "/blog/6f0f4816-0ff4-4215-9eb3-2091fa846e9e/back_system",
    //   element: <RouteVerifyHook element={<BackSystem />} />,
    //   errorElement: <ErrorPage />,
    // },
    {
      path: "/dashboard",
      element: <Dashboard />,
      errorElement: <ErrorPage />,
    },
    {
      path: "systemDesign",
      element: <SystemDesignHome />,
      errorElement: <ErrorPage />,
    },
    // {
    //   path: "/Raylandex",
    //   element: <CompanyHome />,
    //   errorElement: <ErrorPage />,
    // },
  ])

  const root = document.getElementById("root") as HTMLElement
  root.setAttribute("notranslate", "true")

  return (
    <React.StrictMode>
      {/* //TODO 音樂全局  */}
      <MusicProvider>
        {/* //TODO 資料全局 */}
        <ArticleProvider>
          {/* //TODO MUI全局  */}
          <ThemeProvider theme={Theme}>
            <SpotifyEmbed />
            <RouterProvider router={router} />
          </ThemeProvider>
        </ArticleProvider>
      </MusicProvider>
    </React.StrictMode>
  )
}
const root = document.getElementById("root") as HTMLElement

//在Root設置屬性: 關閉翻譯功能
root.setAttribute("notranslate", "true")

ReactDOM.createRoot(root).render(<App />)
